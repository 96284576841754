import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import CookieConsent from './CookieConsent'

const Footer = () => {
	const setDataCopyright = new Date().getFullYear();
	
	return (
		<div className="footer">
			<Container>
				<Row className="app-footer-detail-links-row-custom">
					<Col xs={12} md={3} lg={3}>
						<div className="app-footer-logo">
							<img className="app-logo-footer" src="/app-logo-footer.svg" alt="app footer logo" />
							<img className="app-logo-footer-dark" src="/app-logo-footer-dark.svg" alt="app footer logo" />
							<h6>Appvity consists of a team of professionals 
								that aspires to build productivity software 
								that enables information professionals to 
								work effectively. We build products that focus 
								on Azure and Office 365 cloud platform.</h6>
						</div>
					</Col>
					<Col xs={12} md={1} lg={1}></Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5>Company</h5>
							<li>
								<h6><Link to="/about-us">About Us</Link></h6>
								<h6><Link to="/support/faqs">FAQs</Link></h6>
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5>Works</h5>
							<li>
								<h6><Link to="/products">Products</Link></h6>
								<h6><Link to="/solutions">Solutions</Link></h6>
								<h6><Link to="/services">Services</Link></h6>
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5>Support</h5>
							<li>
								<h6><Link to="/support">Support Center</Link></h6>
								<h6><a href="/OnlineDoc">Online Docs</a></h6>
								<h6><Link to="/support/blogs">Blogs</Link></h6>
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<div className="app-footer-partner-certificate">
							{/* <img className="app-icon-partner" src="/app-icon-partner.svg" alt="app icon partner" />
							<img className="app-icon-partner-dark" src="/app-icon-partner-dark.svg" alt="app icon partner" /> */}
							<img src="/app-icon-authorize.svg" alt="app icon authorize" />
						</div>
					</Col>
				</Row>
				<Row className="app-footer-copyright-anotherlinks-row-custom">
					<Col xs={12} md={12} lg={4} className="app-footer-copyright-col-custom">
						<div className="app-footer-copyright">
							<h6>© {setDataCopyright} Appvity. All rights reserved.</h6>
						</div>
					</Col>
					<Col xs={12} md={12} lg={8} className="app-footer-anotherlinks-col-custom">
						<ul className="app-footer-another-links">
							<li><Link to="/privacy-policy">Privacy Policy</Link></li>
							<li><Link to="/terms-of-service">Terms of Service</Link></li>
							<li><Link to="/accessibility">Accessibility</Link></li>
							<li><Link to="/sitemap">Site Map</Link></li>
						</ul>
						<div className="app-footer-social">
							<Link to="/contact-us">
								<img 
									className="app-icon-contact-us"
									src="/app-icon-contact-us.svg" 
									alt="app icon contact us" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us-hover.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
								/>
								<img 
									className="app-icon-contact-us-dark"
									src="/app-icon-contact-us-dark.svg" 
									alt="app icon contact us dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-hover-dark.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
								/>
							</Link>
							<a href="https://www.facebook.com/appvity">
								<img 
									className="app-icon-facebook"
									src="/app-icon-facebook.svg" 
									alt="app icon facebook" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook-hover.svg')}
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
								/>
								<img 
									className="app-icon-facebook-dark"
									src="/app-icon-facebook-dark.svg" 
									alt="app icon facebook dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-hover-dark.svg')}
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
								/>
							</a>
							<a href="https://www.linkedin.com/company/appvity">
								<img 
									className="app-icon-linkedin"
									src="/app-icon-linkedin.svg" 
									alt="app icon linkedin" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin-hover.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
								/>
								<img 
									className="app-icon-linkedin-dark"
									src="/app-icon-linkedin-dark.svg" 
									alt="app icon linkedin dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-hover-dark.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
								/>
							</a>
						</div>
					</Col>
				</Row>
			</Container>
			<CookieConsent/>
		</div>
	)
}
export default Footer